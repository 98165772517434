
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiWarehouses, ApiSalesOrders } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  setModuleBCN,
} from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";

export default defineComponent({
  name: "organisation-and-facility-warehouse-information",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      status: "",
      id: 1,
      created_at: "",
      updated_at: "",
      deleted_at: "",
      created_uid: 0,
      updated_uid: 0,
      deleted_uid: 0,
      name: "",
      code: "",
      short_code: "",
      type: "",
      merchant: "",
      additional_recipient_name: "",
      contact_name: "",
      taggings: [],
      function_type: "",
      latitude: "",
      longitude: "",
      contact_email: "",
      contact_phone: "",
      contact_fax: "",
      sort_order: 0,
      country: "",
      state_province: "",
      city: "",
      street: "",
      postcode: "",
      description: "",
    });

    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      code: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      short_code: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      function_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      type: [] as TaggingItem[],
      taggings: [] as TaggingItem[],
      function_type: [] as TaggingItem[],
      merchant: [],
      country: [],
    });

    const getCustomerOptions = (query?: string, id?: string) => {
      // let d = id ? { id: id } : { search_value: query };
      // ApiBase.getUserSourceData(d)
      //   .then(({ data }) => {
      //     if (data.code == 0) {
      //       options.value.customer = data.data;
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.country = data.data;
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "warehouse_type",
          "warehouse_taggings",
          "warehouse_function_type",
        ],
      });
      if (data.code == 0) {
        options.value.type = data.data.warehouse_type.items;
        options.value.taggings = data.data.warehouse_taggings.items;
        options.value.function_type = data.data.warehouse_function_type.items;
      }
    };

    const getShowInfo = async () => {
      const { data } = await ApiWarehouses.showWarehouse({
        id: route.params.id,
      });
      if (data.code == 0) {
        formData.value = data.data;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      loading.value = true;
      Promise.all([
        getCountryData(),
        getTaggingData(),
        getShowInfo(),
        getMerchantSourceData(""),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiWarehouses.updateWarehouse(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  //
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      commonBackToList(router, "/organisation-and-facility/warehouse");
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      submit,
      backToList,
      searchMerchantSourceItems,
    };
  },
});
